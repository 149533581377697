.App {
  text-align: center;
  padding: 0;
}

td {
  border-bottom: 1px solid lightgrey;
}

.app-container {
  min-height: 100vh;
  margin: 0;
}

.subscription-head, .projects-box {
  height: 90%;
  border: 1px solid #6D8472;
  border-radius: 15px;
  justify-content: center;
}

#premium-subscription {
  background-color: #876a37;
}

#plus-subscription {
  background-color: #623330;
}

#base-subscription {
  background-color: #312f46;
}

.projects-box:hover, .projects-box:focus {
  background-color: #6D8472;
  color: #09110B;
}

.projects-box:hover {
  transition: 
    background-color 0.2s,
    color 0.2s;
}

@keyframes animateErrorModal {
  from {top: -300px}
  to {top: 10%}
}

@keyframes animateAddOnModal {
  from {top: -300px}
  to {top: 50%}
}

.add-on-box:hover {
  background-color: rgba(256, 256, 256, 0.3);
  color: #09110B;
}

.projects-container::-webkit-scrollbar {
  display: none;
}

.form-input-box {
  width: 100%;
  border: 2px solid transparent;
  background-color: #e8e5df;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
  font-size: .7rem;
  margin-bottom: 10px;
  border-radius: 1px;
}

.send-button:hover {
  background-color: #6D8472;
  color: #e8e5df;
}

.plan-container:hover {
  border: 3px solid #6D8472;
}

.fade-enter {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.fade-exit {
  opacity: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.next-button:hover {
  background-color: hsl(210, 99%, 50%);
}

.dashboard-to-experiences-button:hover {
  opacity: 50%
}

.contact-link-button:hover {
  opacity: 50%
}